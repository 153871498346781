<template>
  <div id="programEventEdit">
    <app-menu></app-menu>
    <div class="col-lg-10 offset-lg-1 text-center">
      <h3 class="text-center">Vytvořit Událost</h3>
      <div class="row">
        <div class="col-lg-12 row text-lg-left">
          <div class="form-group col-lg-6">
            <label for="SelectStatus">Vyberte Stage:</label>
            <select class="form-control" v-model="event.stageId" id="SelectStatus">
              <option v-for="stage in stages" v-bind:key="stage.id" v-bind:value="stage.id">
                {{ stage.name.cs }}
              </option>
            </select>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="multipleInterpretsSelect">Vyberte interprety (multiselect):</label>
              <multiselect
                  id="multipleInterpretsSelect"
                  v-model="event.interprets"
                  :options="interprets"
                  placeholder="vyberte interprety"
                  track-by="id"
                  :multiple="true"
                  :custom-label="getName"
                  value="id"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-12 row text-lg-left">
          <div class="col-lg-6">
            <label>Datum:</label>
            <input type="date" v-model="event.date" id="inputMDEx1" class="form-control">
          </div>
          <div class="col-lg-3">
            <label>Od:</label>
            <time-edit
                :date="event.date"
                v-model="event.timeFrom"
                class="form-control"/>
          </div>
          <div class="col-lg-3">
            <label>Do:</label>
            <time-edit
                :date="event.date"
                v-model="event.timeTo"
                class="form-control"/>
          </div>
        </div>
      </div>
      <div class="row pt-lg-4 text-lg-left">
        <div class="col-lg-6">
          <h4 class="text-center">Čeština</h4>
          <form>
            <div class="form-group">
              <label>Spolupráce:</label>
              <input type="text" class="form-control" v-model="event.collaboration.cs"
                     placeholder="Spolupráce">
            </div>
          </form>
        </div>
        <div class="col-lg-6">
          <h4 class="text-center">Angličtina</h4>
          <form>
            <div class="form-group">
              <label>Spolupráce:</label>
              <input type="text" class="form-control" v-model="event.collaboration.en"
                     placeholder="Spolupráce">
            </div>
          </form>
        </div>
      </div>
      <div class="pt-lg-4 text-lg-left">
        <form>
          <div class="form-group">
            <label for="formTicket">Vstupenky:</label>
            <input type="text" class="form-control" id="formTicket" v-model="event.ticketLink"
                   placeholder="Adrese vstupenek nebo text 'free' pro případ vstupenek zdarma">
          </div>
        </form>
      </div>
      <div class="text-center pt-lg-4">
        <send-button
            :send="create"
            text="Uložit Událost"
            :response="error"
        />
      </div>
    </div>
    <error-modal :message="error"/>
  </div>
</template>

<script>
import axios from "@/api/axiom-instance";
import AdminMenu from "@/components/admin/AdminMenu";
import TimeEdit from "@/components/admin/Program/TimeEdit";
import ErrorModal from "@/components/admin/ErrorModal";
import SendButton from "@/components/admin/SendButton";
import Multiselect from 'vue-multiselect'

export default {
  name: "programEventEdit",
  components: {
    'app-menu': AdminMenu,
    'time-edit': TimeEdit,
    'error-modal': ErrorModal,
    'send-button': SendButton,
    'multiselect': Multiselect
  },
  data() {
    return {
      stages: [],
      interprets: [],
      event: {collaboration: {}, interprets: []},
      error: null
    }
  },
  created() {
    axios.get("/stage/list").then((success) => {
      this.stages = success.data
    }).catch((error) => this.error = error)

    axios.get("/interpret/list").then((success) => {
      this.interprets = success.data
    }).catch((error) => this.error = error)
    if (this.$route.params.id != null) {
      this.load(this.$route.params.id)
    }
  },
  methods: {
    create: function () {
      axios.post("/event", this.event).then(() => {
        this.$router.push({path: "/admin/program/event"})
      }).catch((error) => this.error = error)
    },
    load: function (id) {
      axios
          .get("/event", {params: {id: id}})
          .then((success) => {
            this.event = success.data;
          }).catch((error) => this.error = error)
    },
    getName: function (interpret) {
      return interpret.name.cs;
    }
  }
}
</script>

<style scoped>
#programEventEdit {
  background-color: white;
  min-height: 100vh;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
