<template>
    <input type="text"
           v-model="text"
           @change="blur"
           class="form-control">
</template>

<script>

import moment from "moment";

export default {
  name: "TimeEdit",
  props: ["date", "value"],
  computed: {
    dateTimeInternal: {
      get: function(){
        return this.value;
      },
      set: function(newValue){
        this.$emit('input', newValue)
      }
    }
  },
  watch: {
    date: function () {
      this.text = ""
      this.load()
    }
  },
  created() {
    this.load()
  },
  data() {
    return {
      text: "",
    }
  },
  methods: {
    validate: function () {
      console.log(this.text)
      if (this.text > 5) {
        this.text = ""
      }
    },
    load: function () {
      if (this.value && this.date) {
        let diff = (moment(this.value).valueOf() - moment(this.date).valueOf()) / (1000 * 60);
        this.text = Math.floor(diff / 60) + ":" + ("0" + (diff % 60)).slice(-2)
      } else {
        this.text = ""
      }
    },
    isNumeric: function (str) {
      if (typeof str != "string") return false
      return !isNaN(str) && !isNaN(parseFloat(str))
    },
    blur: function () {
      console.log(this.text)
      if (this.text.length <= 2 && this.isNumeric(this.text)) {
        this.text += ":00"
      } else if (this.text.includes(":")) {
        let values = this.text.split(":")
        if (values.length !== 2) {
          this.text = ""
        } else if (this.isNumeric(values[0]) && this.isNumeric(values[1]) && values[1].length === 2) {
          // pass
        } else {
          this.text = ""
        }
      }
      let values = this.text.split(":")
      if (this.text.length === 5) {
        this.dateTimeInternal = moment(this.date).add(parseInt(values[0]) * 60 + parseInt(values[1]), 'minutes').format("yyyy-MM-DD HH:mm")
      } else {
        this.dateTimeInternal = ""
      }
    }
  }
}
</script>

<style scoped>
p {
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.admin-header {
  margin-top: 10px;
  margin-bottom: 45px;
}

.admin-header h3 {
  font-size: 18pt;
}

.card-title {
  margin-bottom: 0;
}

.admin-action-image {
  margin: 0 5px
}

.admin-line-name {
  font-size: 15pt;
}

.admin-list-card {
  padding: 5px 20px;
  margin: 5px;
}

</style>
